<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">技术赋能·方案详情</div>
      <div class="sub-title">技术赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙为品牌商打造M2C品牌专属私域小程序、M2B供应链共创共享平台，以及会员中心、营销中心、客服中心、数据中心、CRM分析、员工层级、数智导购、财务结算等多个数智化管理工具，帮助品牌商建立自身数字化私域平台，连接零售侧、支付侧等多个渠道，对接公域业务集单处理，实时掌握商品全域销售一线动态，实现科学管理、智慧经营。
      </div>
    </div>
    <img
      class="large-image-h572"
      src="@/image/brand/img_m2b_ skill_brand@3x.png"
    />
    <img
      class="large-image-h270"
      src="@/image/brand/img_oms_ skill_brand@3x.png"
    />
    <img
      class="large-image-h328"
      src="@/image/brand/img_member_ skill_brand@3x.png"
    />
    <img
      class="large-image-h270"
      src="@/image/brand/img_ground_ skill_brand@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">技术赋能·应用案例</div>
      <div class="func-sub-title">定制雪花啤酒小程序，助力品牌深耕私域</div>
      <div class="func-module-tag-container">
        <div class="func-module-tag-title">实施背景</div>
        <div class="func-module-tag-content">
          雪花啤酒山西分公司经过多次与全球蛙的营销合作，在爆品打造和品牌派券活动中取得良好效果。继而，雪花啤酒提出从技术到运营，全面启动私域搭建助力业务成长的需求，全球蛙第一时间积极响应。
        </div>
      </div>
      <div class="func-module-tag-container">
        <div class="func-module-tag-title">实施过程</div>
        <div class="func-module-tag-content">
          全球蛙充分了解雪花啤酒山西分公司私域营销需求，为其量身打造微信、支付宝、云闪付等小程序，提供从技术开发、运营推广到技术支撑的全链路深度服务。小程序集商品销售、会员管理、员工管理、代理商/零售商渠道管理于一身，助力品牌实现数智化私域开发，提升运营效率。
        </div>
      </div>
      <div class="func-module-tag-container">
        <div class="func-module-tag-title">实施结果</div>
        <div class="func-module-tag-content">
          2022年8月，全球蛙为雪花啤酒量身定制微信及支付宝小程序，实现商品直销、导购分销、导购管理及渠道监测等重要功能，夯实私域营销基础，提供导购培训、会员在线、渠道配置等服务。带动全部品牌导购开始分销动作的同时将代理商、零售商的库存、销售等情况，通过匹配的b端产品管理起来。
        </div>
      </div>
    </div>
    <div class="swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(src, index) in swipers"
          :key="`swipers${index}`"
        >
          <img :src="src" />
        </div>
      </div>
    </div>
    <action-sheet module="新品牌技术赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data: () => ({
    swipers: [
      require("@/image/brand/img_xhpj_ skill_brand@3x.png"),
      require("@/image/brand/img_pj_ skill_brand@3x.png"),
      require("@/image/brand/img_my_ skill_brand@3x.png"),
      require("@/image/brand/img_xhpj1_ skill_brand@3x.png"),
    ],
  }),
  mounted() {
    new Swiper(".swiper", {
      loop: true, // 循环模式选项
      initialSlide: 0,
      slidesPerView: "auto",
      speed: 500,
      centeredSlides: true,
      // spaceBetween: 20,
      autoHeight: true,
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-image-h270:last-of-type {
  margin-bottom: 30px;
}
.func-module-container {
  padding-bottom: 60px !important;
}
.large-paragraph-describe {
  margin-bottom: 40px !important;
}
.swiper {
  background-color: $bgColorf2f5fa !important;
  padding-bottom: 60px;
  box-sizing: border-box;
  .swiper-wrapper {
    background-color: $bgColorf2f5fa !important;
    padding-bottom: 60px;
    box-sizing: border-box;
    .swiper-slide {
      width: 500px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 500px;
        height: 640px;
        transform: scale(0.85);
        transition: all 0.8s;
      }
    }
    .swiper-slide-active {
      img {
        transform: scale(1);
      }
    }
  }
}
</style>
